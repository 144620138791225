import React from 'react';

export const Github: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 121 119">
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="GitHub-Mark" fill="#FFFFFE">
            <path d="M60.609,0.446 C27.262,0.446 0.221,27.481 0.221,60.833 C0.221,87.514 17.524,110.15 41.518,118.135 C44.536,118.694 45.644,116.825 45.644,115.23 C45.644,113.79 45.588,109.033 45.562,103.987 C28.762,107.64 25.217,96.862 25.217,96.862 C22.47,89.882 18.512,88.026 18.512,88.026 C13.033,84.278 18.925,84.355 18.925,84.355 C24.989,84.78 28.182,90.578 28.182,90.578 C33.568,99.81 42.309,97.141 45.755,95.598 C46.297,91.696 47.862,89.03 49.589,87.523 C36.176,85.996 22.076,80.818 22.076,57.679 C22.076,51.086 24.435,45.699 28.298,41.47 C27.671,39.948 25.604,33.807 28.883,25.489 C28.883,25.489 33.954,23.866 45.494,31.679 C50.311,30.341 55.477,29.67 60.609,29.647 C65.741,29.67 70.911,30.341 75.737,31.679 C87.263,23.866 92.327,25.489 92.327,25.489 C95.614,33.807 93.546,39.948 92.919,41.47 C96.791,45.699 99.134,51.086 99.134,57.679 C99.134,80.873 85.007,85.98 71.56,87.475 C73.726,89.349 75.656,93.024 75.656,98.658 C75.656,106.737 75.586,113.24 75.586,115.23 C75.586,116.837 76.673,118.72 79.734,118.127 C103.715,110.133 120.996,87.505 120.996,60.833 C120.996,27.481 93.959,0.446 60.609,0.446" id="Fill-52"/>
            <path d="M23.093,87.149 C22.96,87.45 22.488,87.539 22.058,87.333 C21.62,87.136 21.374,86.727 21.516,86.426 C21.646,86.117 22.119,86.032 22.556,86.237 C22.995,86.435 23.245,86.848 23.093,87.149" id="Fill-53"/>
            <path d="M25.539,89.877 C25.251,90.144 24.688,90.02 24.306,89.598 C23.911,89.177 23.837,88.614 24.129,88.343 C24.426,88.076 24.972,88.201 25.368,88.622 C25.763,89.048 25.84,89.607 25.539,89.877" id="Fill-54"/>
            <path d="M27.92,93.355 C27.55,93.612 26.945,93.371 26.571,92.834 C26.201,92.297 26.201,91.653 26.579,91.395 C26.954,91.137 27.55,91.369 27.929,91.902 C28.298,92.447 28.298,93.092 27.92,93.355" id="Fill-55"/>
            <path d="M31.182,96.715 C30.851,97.08 30.146,96.982 29.63,96.484 C29.102,95.997 28.955,95.306 29.287,94.941 C29.622,94.575 30.331,94.678 30.851,95.172 C31.375,95.658 31.535,96.354 31.182,96.715" id="Fill-56"/>
            <path d="M35.682,98.666 C35.536,99.139 34.857,99.354 34.173,99.153 C33.49,98.946 33.043,98.392 33.181,97.914 C33.323,97.438 34.005,97.214 34.694,97.429 C35.376,97.635 35.824,98.185 35.682,98.666" id="Fill-57"/>
            <path d="M40.624,99.028 C40.641,99.526 40.061,99.939 39.343,99.947 C38.621,99.964 38.037,99.561 38.029,99.071 C38.029,98.568 38.596,98.159 39.318,98.147 C40.036,98.133 40.624,98.533 40.624,99.028" id="Fill-58"/>
            <path d="M45.223,98.245 C45.309,98.731 44.81,99.23 44.097,99.362 C43.396,99.491 42.747,99.191 42.658,98.709 C42.571,98.211 43.079,97.712 43.779,97.583 C44.493,97.459 45.132,97.751 45.223,98.245" id="Fill-59"/>
        </g>
    </g>
</svg>
);
