export interface WebsiteConfig {
  title: string;
  description: string;
  coverImage?: string;
  logo: string;
  /**
   * Specifying a valid BCP 47 language helps screen readers announce text properly.
   * See: https://dequeuniversity.com/rules/axe/2.2/valid-lang
   */
  lang: string;
  /**
   * blog full path, no ending slash!
   */
  siteUrl: string;
  /**
   * full url, no username
   */
  facebook?: string;
  /**
   * full url, no username
   */
  twitter?: string;
  github?: string;
  /**
   * hide or show all email subscribe boxes
   */
  instagram?: string;
  website?: string;
  showSubscribe: boolean;
  /**
   * create a list on mailchimp and then create an embeddable signup form. this is the form action
   */
  mailchimpAction?: string;
  /**
   * this is the hidden input field name
   */
  mailchimpName?: string;
  /**
   * name and id of the mailchimp email field
   */
  mailchimpEmailFieldName?: string;
  /**
  /**
   * Meta tag for Google Webmaster Tools
   */
  googleSiteVerification?: string;
  /**
  /**
   * Appears alongside the footer, after the credits
   */
  footer?: string;
}

const config: WebsiteConfig = {
  title: 'Sunny\'s Blog',
  description: 'My thoughts, opinions and projects',
  coverImage: 'img/nasabg.jpg',
  logo: 'img/homepage-logo.png',
  lang: 'en',
  siteUrl: 'https://blog.sunnyamrat.com',
  twitter: 'https://twitter.com/sunnyamrat',
  github: 'https://github.com/sa2812/',
  instagram: 'https://instagram.com/sunnyamrat',
  website: 'https://sunnyamrat.com/',
  showSubscribe: true,
  mailchimpAction: 'https://sunnyamrat.us10.list-manage.com/subscribe/post?u=7179b4752a30d29715ac7d310&amp;id=c5361d2943',
  mailchimpName: 'b_7179b4752a30d29715ac7d310_c5361d2943',
  mailchimpEmailFieldName: 'EMAIL',
//   googleSiteVerification: 'GoogleCode',
//   footer: 'is based on Gatsby Casper',
};

export default config;